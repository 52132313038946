import * as React from 'react';
import AustinMathisHeadshot from '../../images/austin-mathis-headshot';

import Layout from '../../components/layout';

export default () => (
  <Layout>
    <div className="bg-gray-100">
      <div className="pt-12 sm:pt-16 lg:pt-20">
        <div className="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
          <div className="relative py-16 overflow-hidden bg-white">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
              <div className="relative h-full mx-auto text-lg max-w-prose">
                <svg
                  className="absolute transform translate-x-32 top-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
                  />
                </svg>
                <svg
                  className="absolute transform -translate-x-32 -translate-y-1/2 top-1/2 right-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
                  />
                </svg>
                <svg
                  className="absolute transform translate-x-32 bottom-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="d3eb07ae-5182-43e6-857d-35c643af9034"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
                  />
                </svg>
              </div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="mx-auto mb-6 text-lg prose">
                <p className="text-base font-semibold leading-6 tracking-wide text-center text-teal-600 uppercase">
                  ERP Success Story
                </p>
                <h1 className="mt-2 mb-8 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl sm:leading-10">
                  Candlemaker uses ERP to eliminate manufacturing inefficiencies
                </h1>
                <p>
                  <a href="https://empirecandle.com/">Empire Candle Co.</a> knew
                  they had a problem. As IT director Austin Mathis says, “Even
                  as a small business, it’s incredible how much you can miss in
                  terms of inventory and supplies. These small misses add up. In
                  our case, these misses were severely affecting our profits.”
                </p>
                <figure>
                  <AustinMathisHeadshot className="w-full rounded" />
                  <figcaption className="text-center">
                    Austin Mathis of Empire Candle Co. (Image provided by Sage
                    Group)
                  </figcaption>
                </figure>
                <p>
                  Mathis zeroed in on three specific challenges:
                  <ul>
                    <li>
                      Inventory counts were off, which led to ordering too much,
                      mismanaging supplies on hand, and difficulty filling
                      orders.
                    </li>
                    <li>
                      Staffing levels fluctuated with seasonal demand, causing
                      surges in temporary staff, overtime shifts, and penalties
                      by shippers for not being ready to receive materials.
                    </li>
                    <li>
                      Unreliable forecasts of demand for products and
                      availability of materials resulted in buying too much
                      material and creating too much inventory.
                    </li>
                  </ul>
                </p>
                <p>
                  But after implementing an ERP software system, Mathis says,
                  things improved greatly. “The software created a positive
                  ripple across our company’s operations. Today, we order less,
                  create less waste, need less space and fill orders more
                  efficiently.”
                </p>
                <p>
                  Get the full story on how Empire Candle partnered with an ERP
                  installer to get their inventory under control.{' '}
                  <a href="https://www.softwareadvice.com/resources/manufacturing-workflow/">
                    Read more.
                  </a>
                </p>
                <hr />
                <p>
                  Want to hear more? Contact us for a demo. Email{' '}
                  <a href="mailto:chris@monterey.dev">chris@monterey.dev</a> for
                  details.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
